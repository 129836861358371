import React from 'react'
import wispeek from '../../assets/images/home/wispeek.png'
import shape2 from '../../assets/images/our-mission/our-mission-shape2.png'
import serviceShape4 from '../../assets/images/services/service-shape4.png'
import formulaires from '../../assets/images/icones/formulaires.gif'
import plan from '../../assets/images/icones/plan.gif'
import email from '../../assets/images/icones/e-mail.gif'
import idee from '../../assets/images/icones/idee.gif'
import risque from '../../assets/images/icones/risque.gif'
import bibliotheque from '../../assets/images/icones/bibliotheque.gif'

const Content = () => {
    return (
        <div className="about-area ptb-70">
            <div className="container pb-30">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>Déployez votre démarche QVCT grâce au numérique</h2>
                                <h4 className="bottom">Que vous soyez RH, dirigeant, consultant, responsable QVCT, cette solution est faite pour vous !</h4>
                                <p>La QVCT, c’est donner les moyens aux collaborateurs de s’exprimer !<br></br>
                                Cette notion influence de plus en plus la performance des collaborateurs.<br></br>
                                La <span className='bold'>transformation numérique des entreprises</span>, télétravail et travail nomade, horaires décalés, entre autres, ont redéfini nos méthodes de travail.</p>
                                <p><span className='bold color-compliance'>Wispeek</span> vous accompagne dans vos missions pour <span className='bold'>diffuser votre politique QVCT</span>, animer la vie de l’entreprise, faciliter le suivi de vos démarches de prévention et de communication.<br></br>
                                Différentes fonctionnalités pour <span className='bold'>améliorer la communication ascendante et descendante</span> entre l’ensemble de vos collaborateurs, direction et organisations syndicales.<br></br>
                                Un espace plus cadré qu’un simple réseau social d’entreprise qui permet à chacun de <span className='bold'>s’exprimer librement dans un environnement sécurisé</span>.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={wispeek} alt="banner" />
                            <div className="shape">
                                <img src={shape2} alt="banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container pt-50 custom-box'>
                <div className="row" id="qvct">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={formulaires} alt="services" />
                            </div>
                            <h3>
                            Formulaires numériques
                            </h3>
                            <p><span className='bold'>Communiquez</span> simplement vos documents en <span className='bold'>temps réel</span> et en un seul clic à l’ensemble des collaborateurs. Des formulaires publics ou privés, qui garantissent la confidentialité ou l’anonymat des participants.</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                            <div className="overview-box"> 
                                <div className="overview-content">
                                    <div className="content">
                                        <div className="features-text">
                                            <h4><i className="flaticon-tick"></i> Enquêtes de satisfaction</h4>
                                            <h4><i className="flaticon-tick"></i> Sondages et questionnaires</h4>
                                            <h4><i className="flaticon-tick"></i> Besoins en formation</h4>
                                            <h4><i className="flaticon-tick"></i> Évaluation professionnelle</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={plan} alt="services" />
                            </div>
                            <h3>
                            Plans d’actions et analyses
                            </h3>
                            <p>Chaque formulaire donne accès à un <span className='bold'>plan d’action intégré</span> qui permet :</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                            <div className="overview-box"> 
                                <div className="overview-content">
                                    <div className="content">
                                        <div className="features-text">
                                            <h4><i className="flaticon-tick"></i> Mise en place et suivi des actions préventives et correctrices</h4>
                                            <h4><i className="flaticon-tick"></i> Analyse automatique des résultats</h4>
                                            <h4><i className="flaticon-tick"></i> Édition de rapports et statistiques</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={email} alt="services" />
                            </div>
                            <h3>
                            Messagerie sécurisée
                            </h3>
                            <p><span className='bold color-compliance'>Wispeek</span> garantit la <span className='bold'>confidentialité des informations échangées</span> pour assurer la sécurité des données internes hors de l’entreprise.</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                            <div className="overview-box"> 
                                <div className="overview-content">
                                    <div className="content">
                                        <div className="features-text">
                                            <h4><i className="flaticon-tick"></i> Communiquez vos informations sur un canal sécurisé</h4>
                                            <h4><i className="flaticon-tick"></i> Accessible à tous les collaborateurs même ceux qui ne sont pas équipés d’une adresse mail professionnelle</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={risque} alt="services" />
                            </div>
                            <h3>
                            Panneau d’affichage digital interactif
                            </h3>
                            <p>Un tableau de bord dynamique, précis et synthétique qui vous donne une vision globale de vos données pour <span className='bold'>optimiser vos prises de décision</span>.</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                            <div className="overview-box"> 
                                <div className="overview-content">
                                    <div className="content">
                                        <div className="features-text">
                                            <h4><i className="flaticon-tick"></i> Suivez en temps réel vos indicateurs QVCT</h4>
                                            <h4><i className="flaticon-tick"></i> Notification des évènements</h4>
                                            <h4><i className="flaticon-tick"></i> Traçabilité de vos données</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={idee} alt="services" />
                            </div>
                            <h3>
                            Boîte à idée digitale
                            </h3>
                            <p>Elle permet à l’ensemble de vos collaborateurs d’<span className='bold'>émettre des suggestions d’amélioration et d’apporter des idées nouvelles</span>. Le digital permet d’optimiser votre boîte à idée d’entreprise, car il permet à tout le monde de participer et surtout en facilite le traitement.</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                            <div className="overview-box"> 
                                <div className="overview-content">
                                    <div className="content">
                                        <div className="features-text">
                                            <h4><i className="flaticon-tick"></i> Recueillez les idées d’amélioration, les nouveautés</h4>
                                            <h4><i className="flaticon-tick"></i> Recueillez les avis de vos équipes</h4>
                                            <h4><i className="flaticon-tick"></i> Traitez rapidement les données</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={bibliotheque} alt="services" />
                            </div>
                            <h3>
                            Bibliothèque de partage
                            </h3>
                            <p>L’avantage de notre solution c’est que vos ressources sont <span className='bold'>accessibles à tout moment et de partout</span> et donc facilite le travail de vos collaborateurs. Un espace de partage d’information permanent de supports écrits, photos ou vidéos.</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                            <div className="overview-box"> 
                                <div className="overview-content">
                                    <div className="content">
                                        <div className="features-text">
                                            <h4><i className="flaticon-tick"></i> Documents obligatoires</h4>
                                            <h4><i className="flaticon-tick"></i> Charte éthique de l’entreprise</h4>
                                            <h4><i className="flaticon-tick"></i> Procédures, manuels, instructions</h4>
                                            <h4><i className="flaticon-tick"></i> Formulaires et questionnaires</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    )
}

export default Content