import React from 'react'
import { Link } from 'gatsby'
//import img10 from '../../assets/images/about/about-img10.png'
import img1 from '../../assets/images/services/service6.png'
import shape1 from '../../assets/images/about/about-shape1.png'
//import shape2 from '../../assets/images/our-mission/our-mission-shape2.png'
import starIcon from '../../assets/images/star-icon.png'

const ModQVCT = () => {
    return (
        <div className="about-area ptb-70 bg-qvct">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={img1} alt="banner" />
                            <div className="shape">
                                <img src={shape1} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content" id='custom-qvct'>
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    Wispeek QVCT
                                </span>
                                <h2>Solution de feedback</h2>
                                <h3>Qualité de Vie et Condition de Travail et prévention des Risques Psychosociaux</h3>
                                <blockquote>
                                    <p className='color-qvct'>ECOUTER VOS COLLABORATEURS<br></br>
                                    Rien n’a plus de valeur qu’un feedback constructif
                                    </p>
                                </blockquote>
                                <p>Depuis le 31 mars 2022, on ne parle plus de <span className='bold'>QVT</span> « qualité de vie au travail » mais de <span className='bold'>QVCT</span> « qualité de vie et des conditions de travail ». La loi Santé au travail du 2 août 2021 modifie l’acronyme afin de <span className='bold'>renforcer la prévention santé au travail</span>.</p>
                                <p>Babyfoot, apéro, salle de sport… La qualité du bien-être au travail répond à des besoins plus profonds pour les collaborateurs.</p>
                                <p>Elle renvoie à des questions individuelles et collectives : <span className='bold'>les conditions et le contenu du travail, l’environnement physique, le dialogue social, la sécurité, la santé, la formation ou encore l’évolution professionnelle</span>.</p>
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Demandez une démo <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModQVCT