import React from 'react'
//import service4 from '../../assets/images/services/service4.png'
//import service5 from '../../assets/images/services/service5.png'
//import service6 from '../../assets/images/services/service6.png'
//import product1 from '../../assets/images/services/product1.png'
//import img1 from '../../assets/images/banner-img3.png'
//import starIcon from '../../assets/images/star-icon.png'

const ContentThree = () => {
    return (
        <div className="overview-area pt-30 pb-70 bg-qvct">
            <div className="container">
                <div className="upcoming-bootcamps-content">
                    <h3 className="h2-top bottom">Une solution collaborative au service de votre culture d’entreprise </h3>
                    <p>Faire le choix d'intégrer <span className='bold'>une plateforme collaborative</span> au sein de votre organisation permet d’impliquer l’ensemble de vos équipes et de répondre à vos besoins au quotidien.</p>
                    <p>Après cette crise sanitaire, l’enjeu est de <span className='bold'>replacer l’humain au centre de l’entreprise</span>, en intégrant dans vos prérogatives la santé, l’approche environnementale, les enjeux d’égalité et les conditions de travail.</p>
                    <p>L’objectif est d’<span className='bold'>inscrire la QVCT dans la démarche de progrès continu</span> de votre organisation aussi bien au niveau de vos valeurs et engagements.</p>
                </div>
            </div>
        </div>
    )
}

export default ContentThree