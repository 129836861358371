import React from 'react'
import rps from '../../assets/images/services/rps.png'
import yoga from '../../assets/images/yoga.png'
import { Link } from 'gatsby'

const ContentTwo = () => {
    return (
        <div className="upcoming-bootcamps-area pt-70 pb-30 bg-qvct">
            <div className="container">
                <div className="upcoming-bootcamps-content">
                    <h2 className="pb-50">Optimisez la prévention et la gestion des RPS</h2>
                    <p>Comme l’impose la loi Santé au travail, les <span className='bold'>risques psychosociaux</span> (RPS) doivent être pris en compte au même titre que les autres risques professionnels.</p>
                    <p><span className='bold color-compliance'>Wispeek</span> est une solution numérique pour <span className='bold'>prévenir, suivre et contrôler en temps réel les situations à risques</span>, dépression, burn out, stress, harcèlements, etc.</p>
                </div>

                

                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            <img src={rps} alt="about" />
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content ptb-30">
                        <p>L’<span className='bold'>INRS</span> (Institut National de Recherche et de Sécurité pour la prévention des accidents du travail et des maladies professionnelles) a répertorié 7 familles de facteurs qui sont :</p>
                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Intensité et complexité au travail</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Horaires de travail difficiles</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Exigences émotionnelles</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Faible autonomie au travail</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Rapports sociaux au travail dégradés</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Conflits et valeurs</h4>
                            </div>

                            <div className="features-text pb-30">
                                <h4><i className="flaticon-tick"></i> Insécurité de l’emploi et du travail</h4>
                            </div>
                        <p className='article-link'>Les RPS doivent être <span className='bold'>évalués et intégrés</span> <Link to="/qhse">au document unique d’évaluation des risques professionnels</Link> (DUER).</p>
                        <p>Pour vous aider dans cette démarche, l’outil <span className='bold color-compliance'>Wispeek</span> vous permet de <span className='bold'>prévenir, suivre et contrôler en temps réel</span> les situations à risques dans chaque unité de travail.</p>
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content pt-30">
                        <p>Une plateforme simple d’accès et d’utilisation où vos collaborateurs peuvent trouver toutes les informations nécessaires mises en place par votre entreprise concernant votre <span className='bold'>démarche de prévention</span>.</p>
                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Bibliothèque de procédures et de conseils</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Questionnaires d’auto-évaluation</h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Formulaires de signalement </h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Tableau de suivi des actions </h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Messagerie sécurisée pour échanger en toute confidentialité ou anonymat</h4>
                            </div>

                        <p className="h2-top">Afin de garantir la santé, la sécurité et l’engagement des collaborateurs, il est essentiel de mettre en place une <span className='bold'>démarche de prévention collective</span>.</p>
                        <p>Dépression, burn out, stress, harcèlements, etc. aboutissent généralement à une augmentation de l’absentéisme, du turnover ce qui impacte l’activité voire l’image de l’entreprise.</p>
                        <p>Il vaut mieux agir avant qu'après et <span className='bold'>fournir à l’ensemble de vos collaborateurs, des moyens, ressources et outils pour se protéger</span>.</p>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={yoga} alt="about" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ContentTwo;