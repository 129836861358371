import React from 'react'
//import service4 from '../../assets/images/services/service4.png'
//import service5 from '../../assets/images/services/service5.png'
//import service6 from '../../assets/images/services/service6.png'
//import product1 from '../../assets/images/services/product1.png'
import img1 from '../../assets/images/banner-img3.png'
import starIcon from '../../assets/images/star-icon.png'

const WhyWispeek = () => {
    return (
        <div className="overview-area ptb-70">

            <div className="container">
                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <div>
                                <div className="about-content">
                                    <div className="content">
                                        <span className="sub-title">
                                            <img src={starIcon} alt="banner" /> 
                                            Pourquoi nous ?
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <h2>Pourquoi choisir Wispeek ? </h2>
                            <ul className="features-list-custom">
                                <li><span><i className='bx bx-check'></i> Améliorer le bien-être des collaborateurs</span></li>
                                <li><span><i className='bx bx-check'></i> Valoriser un engagement collaboratif</span></li>
                                <li><span><i className='bx bx-check'></i> Retenir et attirer les talents</span></li>
                                <li><span><i className='bx bx-check'></i> Développer une démarche d’innovation participative</span></li>
                                <li><span><i className='bx bx-check'></i> Optimiser votre dialogue social</span></li>
                                <li><span><i className='bx bx-check'></i> Mesurer le climat social de votre entreprise</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={img1} alt="features" />
                        </div>
                    </div>
                </div>

                <div className="upcoming-bootcamps-content pt-70">
                    <h4 className="center">Des facteurs de plus en plus considérés dans la recherche de sa future entreprise !</h4>
                </div>

            </div>
        </div>
    )
}

export default WhyWispeek