import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import UnProjet from "../components/Common/UnProjet"
import ModQVCT from "../components/QVCT/ModQVCT"
import Content from "../components/QVCT/Content"
import ContentTwo from "../components/QVCT/ContentTwo"
import ContentThree from "../components/QVCT/ContentThree"
import WhyWispeek from "../components/QVCT/WhyWispeek"
import CartesAvantages from "../components/QVCT/CartesAvantages"
import { Helmet } from "react-helmet"

const QVCT = () => {
  return (
    <Layout>
      <Helmet>
        <title>Wispeek by Seekreet | Solution Saas dédiée à la QVCT/DUER</title>
        <meta name="description" content="Capitalisez les feedbacks autour des questions de bien-être au travail et d’expérience collaborateur pour renforcer votre prévention santé au travail. DUERP 2023" />
      </Helmet>
      <Navbar />
      <PageBanner
          pageTitle="Wispeek - QVCT" 
          homePageText="Accueil" 
          homePageUrl="/" 
          parentActive="Wispeek" 
          parentPageUrl="/wispeek"
          activePageText="QVCT" 
      />
      <ModQVCT />
      <Content />
      <ContentTwo />
      <ContentThree />
      <WhyWispeek />
      <CartesAvantages />
      <UnProjet />
      <Footer />
    </Layout>
  )
}

export default QVCT